// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intrmodl-js": () => import("./../../../src/pages/intrmodl.js" /* webpackChunkName: "component---src-pages-intrmodl-js" */),
  "component---src-pages-slateplan-js": () => import("./../../../src/pages/slateplan.js" /* webpackChunkName: "component---src-pages-slateplan-js" */),
  "component---src-pages-slingshot-js": () => import("./../../../src/pages/slingshot.js" /* webpackChunkName: "component---src-pages-slingshot-js" */)
}

